<template>
<!-- 订单详情 待支付 -->
  <div class="orderDetails">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="bannerImgWrapper">
      <img src="@/assets/orderPayBanner.png" alt="" class="bannerImg">
    </div>
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navTitle">商品名称：</span>
        <span class="navContent">【大流量】随身WiFi设备新款10000G/月</span>
      </li>
      <li class="orderList">
        <span class="navTitle">订单号：</span>
        <span class="navContent">51596165226126</span>
      </li>
      <li class="orderList">
        <span class="navTitle">下单时间：</span>
        <span class="navContent">2021-05-26 15:44:23</span>
      </li>
      <li class="orderList">
        <span class="navTitle">数量：</span>
        <span class="navContent">1</span>
      </li>
      <li class="orderList">
        <span class="navTitle">价格：</span>
        <span class="navContent price">¥199</span>
      </li>
    </ul>
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navTitle">联系人：</span>
        <span class="navContent">孙伟</span>
      </li>
      <li class="orderList">
        <span class="navTitle">联系电话：</span>
        <span class="navContent">15855222222</span>
      </li>
      <li class="orderList">
        <span class="navTitle">配送地址：</span>
        <span class="navContent">龙明小区8号楼1单元203</span>
      </li>
    </ul>
    <ul class="orderWrapper">
      <li class="orderList">
        <span class="navBottomTitle">支付方式：</span>
      </li>
      <li style="margin-top: 10px">
        <van-radio-group v-model="radio">
          <van-cell-group>
            <van-cell clickable @click="radio = '1'">
              <span><i class="iconfont" style="color: #09bb07; margin-right:5px">&#xe600;</i>微信支付</span>
              <template #right-icon>
                <van-radio name="1" checked-color="#ee0a24"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </li>
    </ul>
    <div class="orderBtnWrapper">
      <div class="btn payBtn" @click="againPay">
        立即付款
      </div>
      <div class="btn">
        联系客服
      </div>
    </div>
  </div>
</template>

<script>
import { postAgainPayOrder, getOrderQuery } from 'api/api'
export default {
  name: 'orderDetails',
  data () {
    return {
      radio: '1',
      contentData: ''
    }
  },
  created () {
    this._getOrderQuery()
    
  },
  methods: {
    onClickLeft () {
      this.$router.replace({
        name: 'order',
        params: {
          phone: this.$route.params.phone
        }
      })
    },
    _getOrderQuery () {
      getOrderQuery(this.$route.params.data.orderNum).then(res => {
        console.log('获取信息', res)
      })
    },
    // 二次支付
    againPay () {
      postAgainPayOrder(item.orderNum).then(res => {
        if (res && res.code === 200) {
          let Url = `${this.$store.state.localhostUrl}/result/${this.$route.params.id}`
          let redircetUrl = encodeURIComponent(Url)
          window.location.href = res.result + `&redirect_url=${redircetUrl}`
        } else if (res.code === 201) {
          this.$toast('订单超时请重新支付')
        } else {
          this.$toast(res.message)
        }
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
  .bannerImgWrapper
    height 56px
    padding 10px
    .bannerImg
      height 100%
      width 100%
  .orderDetails
    min-height 100%
    .orderWrapper
      margin 0 13px
      padding 20px 0
      border-bottom 1px solid #F7F7F7
      .orderList
        display flex
        margin-top 17px
        .navTitle
          flex 100px
          display inline-block
          font-size 13px
          font-weight 500
          color #999
        .navContent
          font-size 13px
          font-weight 500
          color #333
        .price
          color #FF5257
        .navBottomTitle
          flex 100px
          font-size 14px
          color #333
        .navBottomContent
          color #999
    .orderBtnWrapper
      display flex
      flex-direction row-reverse
      margin-top 11px
      .btn
        height 26px
        line-height 26px
        font-size 13px
        font-weight 500
        border 1px solid #E6E6E6
        padding 0 16px
        border-radius 13px
        color #333
        margin-right 20px
      .payBtn
        color #FF5257
        border 1px solid #FF5257
  >>> .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after
    border-width 0px
</style>